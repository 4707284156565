@import "../../assets/theme/_var.scss";

.audioFile {
    position: fixed;
    bottom: 50px;
    width: 100px;
    z-index: 9;
    left: 30px;

    @media (max-width:767px) {
        bottom: 50px;
        left: 20px;
    }

    .playAudio {
        position: relative;

        .autoText {
            position: absolute;
            font-weight: bold;
            text-align: center;
            color: $white;
            transform: translateX(-50%);
            left: 50%;
            top: 30px;
            z-index: 1;
            pointer-events: none;
            text-transform: uppercase;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        audio {
            width: 100%;
            opacity: 0;

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }

        button {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            // border: 1px solid #e72d69;
            border: none;
            border-radius: 10px;
            width: 100%;
            height: 70px;
            background: transparent;
            color: #fff;
            border-radius: .3rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: .5rem;
            box-sizing: border-box;

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }

        // .playing {
        //     background: rgba(255, 255, 255, .1);
        //     width: 2rem;
        //     height: 2rem;
        //     border-radius: .3rem;
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: flex-end;
        //     padding: .5rem;
        //     box-sizing: border-box;
        // }
        .Pause {

            &:focus {
                outline: 0;
                box-shadow: none;
            }

            .playing__bar {
                display: inline-block;
                background: white;
                width: 10%;
                height: 100%;
                animation: up-and-down 1.3s ease infinite alternate;
            }

            .playing__bar1 {
                height: 60%;

                background: linear-gradient(0deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            .playing__bar2 {
                height: 30%;
                animation-delay: -2.2s;

                background: linear-gradient(180deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            .playing__bar3 {
                height: 75%;
                animation-delay: -3.7s;

                background: linear-gradient(0deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            @keyframes up-and-down {
                10% {
                    height: 30%;
                }

                30% {
                    height: 100%;
                }

                60% {
                    height: 50%;
                }

                80% {
                    height: 75%;
                }

                100% {
                    height: 60%;
                }
            }

        }

        .Play {
            .playing__bar {
                display: inline-block;
                background: white;
                width: 10%;
                height: 100%;
                animation: unset;
            }

            .playing__bar1 {
                height: 60%;

                background: linear-gradient(0deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            .playing__bar2 {
                height: 30%;
                animation-delay: -2.2s;

                background: linear-gradient(180deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            .playing__bar3 {
                height: 75%;
                animation-delay: -3.7s;

                background: linear-gradient(0deg, rgba(225, 49, 104, 1) 0%, rgba(107, 65, 203, 1) 100%);
                margin: 0 2px;
            }

            @keyframes up-and-down {
                10% {
                    height: 30%;
                }

                30% {
                    height: 100%;
                }

                60% {
                    height: 50%;
                }

                80% {
                    height: 75%;
                }

                100% {
                    height: 60%;
                }
            }

        }
    }
}