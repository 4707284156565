@import '../../../assets/theme/_var.scss';

.timerHandle {
    margin-top: -15px;
    span {
        color: $white;
        font-size: 5.1rem;
        font-family: 'Hanson';
        line-height: 8rem;
    }

    .elseText {
        span {

            font-size: 2rem;
        }
    }
}