// @font-face {
//     font-family: 'Hanson', Poppins, sans-serif;
//     src: url('../fonts/HansonBold.woff2') format('woff2'),
//         url('../fonts/HansonBold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

// fonts

$baseFont: 'Poppins', sans-serif;
// $hansonFont: 'Hanson', Poppins, sans-serif;

// colors
$white: #ffffff;
$black: #000000;
$baseColor: #000347;
$pink: #D80059;
$pinkDark: #9A0542;
$pink700: #E00070;