@import "../../assets/theme/_var.scss";
.about_sec {
    // background-image: url(../../assets/images/banner.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // min-height: 100vh;
    padding-top: 100px;
    &_inner {
        padding-top: 100px;
        padding-bottom: 145px;
    }
    .left {
        h1 {
            color: $white;
            font-size: 9.6rem;
            line-height: 9.9rem;
            font-family: 'Hanson';
            span {
                font-size: 4.9rem;
                display: block;
            }
            @media (max-width:1679px) {
                font-size: 5rem;
            line-height: 5rem;
            span {
                font-size: 2.9rem;
                // display: block;
            }
            }
        }
        h3 {
            font-size: 3.5rem;
            font-weight: 500;
            color: $white;
            line-height: 3.5rem;     
            @media (max-width:1679px) { 
                font-size: 2.5rem;
             }       
        }
        p {
            color: $white;
            font-size: 2.1rem;
            line-height: 3.4rem;
            @media (max-width:1679px) { 
                font-size: 1.8rem;
                line-height: 2.5rem;
             } 
        }
        .commonBtn {
            margin-top: 4.2rem;
        }
    }
    .right {
        width: 665px;
        height: 448px;
        position: relative;
        display: table;
        margin-left: auto;
        .card__box {
             .card__box__pic {
                position: absolute;
                cursor: pointer;
                &.play_one {
                    z-index: 9;
                    transition: all 0.5s;
                }
                &.play_two {
                    z-index: 5;
                    top: 70px;
                    left: 40px;
                }
                &.play_three {
                    z-index: 0;
                    top: 150px;
                    left: 100px;
                }
            }
        }
    }
    .comming_soon {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        z-index: 1;
        &:before {
            content: "";
            display: block;
            height: 322px;
            width: 307px;
            background-image: url(../../assets/images/rocket.png);
            transform: translateX(-50%);
            background-repeat: no-repeat;
            background-size: auto;
            left: 50%;
            top: -260px;
            position: absolute;
        }
        &_inner {
            text-align: center;
            max-width: 704px;
            margin: 0 auto;
            p {
                color: $white;
                font-size: 1.5rem;
                margin: 0;
            }
        }
    }
}
