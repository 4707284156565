@import '../../assets/theme/_var.scss';

.footer {
    margin-top: 3rem;
    padding: 4rem 0 2rem;
    background-image: url(../../assets/images/footerbg.png);
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            flex: 0 0 auto;
            padding: 1rem;

            a {
                display: inline-block;
                transition: .3s all ease-in-out;
                &:hover {
                    color: $pink;
                }
            }
        }

        li,
        li a {
            color: $white;
            font-size: 1.2rem;
        }
    }
}