@import '../../../assets/theme/_var.scss';
.commonBtn {
    font-size: 1.7rem;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 2.8rem;
    padding: 1.8rem 3.5rem;
    border-radius: 1rem;
    transform: skew(-10deg);
    background: transparent linear-gradient(257deg, $pink 0%, $pinkDark 100%) 0% 0% no-repeat padding-box;
    transition: .3s all ease-in-out;
    span {
    transform: skew(10deg);
    color: $white;
        
    }
    &:hover {
        background: transparent linear-gradient(257deg, $pinkDark 0%, $pink 100%) 0% 0% no-repeat padding-box;
    }
     &.big {
        font-size: 2rem;
     }
}