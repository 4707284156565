.connect_wallet {
    .modal-dialog {
        max-width: 578px !important;
        text-align: center;
    }    
    .modal-content {
        .modal-header {
            border: 0;
            padding: 0;
            position: absolute;
            right: 30px;
            top: 30px;
            z-index: 1;
            .btn-close {
                background-image: url(../../assets/images/closeBtn.png);
                opacity: 1;
                height: 24px;
                width: 24px;
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        background-color: transparent;
border: 0;
.modal-body {
    padding: 0;
}
    }
}