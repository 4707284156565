// @import './assets/fonts/fonts.css';
@import './assets/theme/_var.scss';
@font-face {
  // font-family: 'Hanson', Poppins, sans-serif;
  font-family: 'Hanson';
  src: url('./assets/fonts/HansonBold.woff2') format('woff2'),
  url('./assets/fonts/HansonBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


body {
  background-color: $baseColor;
  margin: 0;
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background-image: url(./assets/images/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img,
svg {
  max-width: 100%;
}

html {
  font-size: 60.6666%;
}

// html,
// body,
// #root {
//   height: 100%;
// }

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  max-width: 1690px;
  padding: 0 15px;
}

.row {
  margin: 0 -15px;

  >div {
    padding: 0 15px;
  }
}

.headingFont {
  font-family: 'Hanson', Poppins, sans-serif;
}
button {
  border: 0;
}
*, a,button {
  outline: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.fade.modal-backdrop {
  background: transparent;
	// background: #000000 0% 0% no-repeat padding-box;
	opacity: 1;
	backdrop-filter: blur(18px);
	-webkit-backdrop-filter: blur(18px);
}